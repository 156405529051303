import { defineStore } from 'pinia'
import QuotationService from '@/services/QuotationService'
import type { QuotationDTO, QuotationType } from '@/types/quotationType'
import type { EntityTypeOf } from '@/types/types'

export const useQuotationStore = defineStore({
  id: 'quotations',
  state: (): {
    quotations: Map<
      string,
      QuotationType & { timestamp?: number, outdated?: boolean }
    >
    page: number
    filters?: Record<string, any>
    sortBy?: Record<string, 'ASC' | 'DESC'>
  } => ({
    quotations: new Map<string, QuotationType>(),
    page: 1,
    filters: undefined,
    sortBy: {
      createdAt: 'DESC',
    },
  }),
  actions: {
    async fetchNextPage(clear?: boolean) {
      if (clear) this.page = 1

      return QuotationService.getQuotations({
        page: this.page++,
        filters: this.filters,
        order: this.sortBy,
      })
        .then((res) => {
          if (clear) this.quotations.clear()
          return res
        })
        .then(({ data }) => data.forEach((x) => this.quotations.set(x.id, x)))
    },

    async setFilters(filters: Record<string, any> = {}) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.filters = { ...filters }

        await this.fetchNextPage(true)
      }
    },

    async setSorting(sortBy?: { field: string, direction: 'ASC' | 'DESC' }) {
      if (sortBy !== this.sortBy) {
        this.sortBy = sortBy ? { [sortBy.field]: sortBy.direction } : undefined

        await this.fetchNextPage(true)
      }
    },

    async fetchQuotationById(id: string, cached = true) {
      const local = this.quotations.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime()) ||
        local?.outdated
      ) {
        const { data } = await QuotationService.getQuotationById(id)

        if (data) {
          this.quotations.set(id, {
            ...(local?.outdated ? local : {}),
            ...data,
            timestamp: new Date().getTime(),
            outdated: false,
          })
        }

        return data
      }

      return local
    },

    async fetchQuotationByEntityId(
      entityId: string,
      entityType: EntityTypeOf.CLIENT | EntityTypeOf.ALLOCATION,
    ) {
      return (
        await QuotationService.getQuotations({
          filters: {
            [entityType]: entityId,
            pageSize: 999,
          },
        })
      ).data
    },

    async addNewQuotation(quotation: QuotationDTO) {
      const { data } = await QuotationService.postQuotation(quotation)

      this.quotations.set(data.id, data)

      return data
    },

    async updateQuotation(quotation: QuotationDTO) {
      const { data } = await QuotationService.putClient(quotation)

      this.quotations.set(quotation.id, data)

      return data
    },

    async markQuotationAsOutdated(id: string, watching: boolean) {
      const local = this.quotations.get(id)

      if (local?.timestamp) {
        local.outdated = true

        if (watching) this.fetchQuotationById(id, false)
      }
    },
  },
  getters: {
    dataAsArray: (state) =>
      Array.from<QuotationType>(state.quotations.values()),

    findOrThrowQuotationById: (state) => {
      return (quotationId: string) => {
        const quotation = state.quotations?.get(quotationId)

        if (!quotation) throw new Error('Quotation not found')

        return quotation
      }
    },
  },
})
